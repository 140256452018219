@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-background-secondary);
    color: var(--color-foreground);
  }
}

#root {
  @apply
    min-h-screen
    /* flex
    flex-col
    min-h-screen */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply
    font-semibold
  ;
}

h1 {
  @apply
    text-2xl
  ;
}

h2 {
  @apply
    text-xl
  ;
}

h3 {
  @apply
    text-lg
  ;
}

h4 {
  @apply
    text-base
  ;
}

h5 {
  @apply
    text-sm
  ;
}

h6 {
  @apply
    text-xs
  ;
}

.react-flow__controls {
  overflow: visible;
}

/**
 * Add tooltip to React Flow controls buttons.
 *
 * Default buttons from the controls component cannot be accessed
 * or modified with a custom component, so it is necessary to
 * add tooltips by using the `title` attribute.
 */
.react-flow__controls-button {
  position: relative;
  border: 0 !important;

  &:before {
    content: attr(title);
    position: absolute;
    right: calc(-1 * theme('spacing.2'));
    background: var(--xy-controls-button-background-color);
    color: var(--xy-controls-button-color);
    top: 50%;
    transform: translateY(-50%) translateX(100%);
    width: max-content;
    height: fit-content;
    z-index: theme('zIndex.10');
    border-radius: theme('borderRadius.md');
    padding: theme('padding.1') theme('padding.2');
    text-transform: capitalize;
    display: none;
    font-size: theme('fontSize.xs');
  }

  &:hover:before {
    display: block;
  }
}
